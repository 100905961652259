<template>
  <div class="email-list-wrap">
    <div class="email-list bg-white">
      <div class="title">当前已绑定的邮箱</div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
        >
          <template v-slot:finished>
            <div class="no-data bg-white lh-22" v-if="dataList.length == 0">
              <img src="@img/no-data.png" alt="" />
              暂无数据~
            </div>
            <div v-else></div>
          </template>
          <div class="invoice-list">
            <div
              class="item lh-22"
              v-for="(item, index) in dataList"
              :key="item"
              :class="index == 0 ? '' : 'van-hairline--top'"
            >
              <div>{{ item }}</div>
              <a @click="unbind(item)">解绑邮箱</a>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="instructions" :class="showHelp ? 'bg-white' : ''">
      <div v-show="showHelp">
        <div class="title">使用说明</div>
        <div class="steps">
          <div class="step">
            <img src="@img/step-1.png" alt="" />
            <div class="text">添加个人邮箱</div>
          </div>
          <div class="arrow">
            <img
              src="@img/double-arrow.png"
              alt=""
              style="width: 8px; height: 11px"
            />
          </div>
          <div class="step">
            <img src="@img/step-2.png" alt="" />
            <div class="text">打开个人邮箱</div>
          </div>
          <div class="arrow">
            <img
              src="@img/double-arrow.png"
              alt=""
              style="width: 8px; height: 11px"
            />
          </div>
          <div class="step">
            <img src="@img/step-3.png" alt="" />
            <div class="text">转发发票邮件至官方邮箱</div>
          </div>
        </div>
        <div class="pmemail">
          官方邮箱：pm@ningmengyun.com
          <span
            class="copy"
            v-clipboard:error="onError"
            v-clipboard:copy="'pm@ningmengyun.com'"
            v-clipboard:success="onCopy"
          >
            复制
          </span>
        </div>
      </div>
    </div>
    <div class="btn">
      <van-button type="primary" @click="onClick">添加更多邮箱</van-button>
    </div>
  </div>
</template>

<script>
import { getEmails, unBindEmail } from "../../../http/api";
export default {
  name: "email-list",

  data() {
    return {
      dataList: [],
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      showHelp: false,
    };
  },

  methods: {
    onLoad() {
      if (this.refreshing) {
        if(this.dataList.length > 0) {
          this.showHelp = true
        }
        this.dataList = [];
        this.refreshing = false;
      }
      getEmails()
        .then((res) => {
          this.dataList.push(...res.data);
          this.showHelp = this.dataList.length > 0
          this.loading = false;
          this.finished = true;
        })
        .catch(() => {
          this.error = true;
        });
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.error = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    unbind(email) {
      this.$dialog
        .confirm({
          title: "确定要解绑该邮箱吗？",
          message: email,
          confirmButtonText: "确定",
        })
        .then(() => {
          unBindEmail(email).then((res) => {
            if (!res.statusCode) {
              this.$toast.fail(res.message);
              return;
            }
            this.$toast.success("解绑成功");
            this.refreshing = true;
            this.onRefresh();
          });
        })
        .catch(() => {
          // do nothing
        });
    },

    // 复制成功
    onCopy(e) {
      this.$toast.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$toast.error("复制失败");
    },
    onClick() {
      this.$router.push({ name: "email-bind" });
    },
  },
};
</script>

<style lang="less" scoped>
.email-list-wrap {
  padding: 16px 12px 0;
  display: flex;
  flex-direction: column;
  .email-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding: 20px 0;
    border-radius: 4px;
    .title {
      padding: 0 20px;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      font-weight: 600;
    }
    .van-pull-refresh {
      padding: 0 20px;
      margin-top: 16px;
      overflow: auto;
      flex: 1;
    }
    .item {
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #ebebeb;
      a {
        font-size: 14px;
        color: #06c05f;
      }
    }
  }
  .instructions {
    min-height: 210px;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 18px 16px 22px;
    color: #333333;
    // width: 100%;
    border-radius: 4px;
    .title {
      font-weight: 600;
      text-align: center;
    }
    .steps {
      margin-top: 12px;
      display: flex;
      justify-content: space-around;
      .step {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        text-align: center;
        font-size: 13px;
        img {
          width: 62px;
          height: 62px;
        }
        .text {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
      .arrow {
        height: 62px;
        display: flex;
        align-items: center;
      }
    }
    .pmemail {
      margin-top: 20px;
      font-size: 14px;
      color: #666666;
      text-align: center;
      .copy {
        margin-left: 8px;
        color: #06c05f;
      }
    }
  }
  .btn {
    width: 100%;
    text-align: center;
    button {
      width: 295px;
      height: 40px;
      margin: 16px 0;
      border-radius: 20px;
      background: #33c759;
      font-size: 16px;
    }
  }
}
</style>

<style lang="less">
.van-dialog {
  border-radius: 8px;
  .van-dialog__header {
    padding-top: 32px;
    font-size: 17px;
    font-weight: 600;
  }
  .van-dialog__message--has-title {
    padding-top: 16px;
  }
  .van-button {
    height: 56px;
    font-weight: 600;
  }
  .van-dialog__confirm {
    color: #33c759;
  }
}
</style>
